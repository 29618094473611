<template>
  <Animation3 />

  <div id="team_detail" class="page">
    <div
      class="banner"
      style="    padding-top: 196px; background-color: #000; "
    >
      <!-- <div class="bg"></div> -->
      <img class="bg" alt="Rammet" :style="'background-color: #000'" />

      <div class="container">
        <div class="team-detail">
          <div>
            <span>{{ content.BannerHeadingText }}</span>
            <h1>{{ team_data?.name }}</h1>
            <p class="designation">{{ team_data?.designation }}</p>
            <span class="contact"
              ><a href="#about">
                <DoubleDownArrow class="light" />
                <p>
                  {{ content.ReadMoreButtonText }}
                  {{ team_data?.name.split(" ")[0] }}
                </p></a
              >
            </span>
          </div>
          <div>
            <img :src="IMAGES_PATH + 'team/' + team_data?.img_bg" />
          </div>
        </div>
      </div>
    </div>
    <section class="about container">
      <a-row id="about">
        <a-col
          :sm="{ span: 24 }"
          :md="{ span: 24 }"
          :lg="{ span: 24 }"
          :xl="{ span: 12 }"
          class="py-4"
        >
          <h2>
            {{ content.AboutHeadingText }} {{ team_data?.name.split(" ")[0] }}
          </h2>
          <p>
            {{ team_data?.short_description }}
          </p>
        </a-col>

        <a-col
          :sm="{ span: 24 }"
          :md="{ span: 24 }"
          :lg="{ span: 24 }"
          :xl="{ span: 12 }"
          class="py-4 TeamMemberQuote"
        >
          <blockquote v-if="team_data?.favourite_quote != null">
            "{{ team_data?.favourite_quote }}"
          </blockquote>
          <p style="color: #737070;" v-if="team_data?.quote_author != null">
            - {{ team_data?.quote_author }}
          </p>
        </a-col>
      </a-row>
    </section>
    <section class="social">
      <div class="container ">
        <h2>{{ content.SocialHeadingText }}</h2>
        <div class="flex social-media-links">
          <div>
            <div class="social_icon">
              <a :href="team_data?.facebook_url" target="_blank"
                ><Email />
                <p>{{ team_data?.facebook_url?.split(":")[1] }}</p></a
              >
            </div>
          </div>
          <div>
            <div class="social_icon">
              <a :href="team_data?.twitter_url" target="_blank"
                ><Twitter />
                <p>@{{ team_data?.twitter_url?.split(".com/")[1] }}</p></a
              >
            </div>
          </div>
          <div>
            <div class="social_icon">
              <a :href="team_data?.linkedin_url" target="_blank"
                ><Linkedin />
                <p>/{{ team_data?.linkedin_url?.split(".com/")[1] }}</p></a
              >
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import constant from "../config/constants"; //find all the api urls, assets, images path here

import DoubleDownArrow from "@/assets/icons/double_down_arrow.svg";
import Twitter from "@/assets/icons/twitter.svg";
import Email from "@/assets/icons/envelope.svg";
import Linkedin from "@/assets/icons/linkedin.svg";
import { LanguageStrings } from "@/languages";

import Animation3 from "@/components/Animation3";

import axios from "axios";

export default {
  components: {
    DoubleDownArrow,
    Twitter,
    Linkedin,
    Email,
    Animation3,
  },

  data: function() {
    return {
      content: null,
      team_data: null,
      err: "",
      IMAGES_PATH: constant.IMAGES_URL,
      test_link: "http://www.twitter.com/imad123",
    };
  },

  created() {
    localStorage.getItem("siteLanguage") === "GR"
      ? (this.content = LanguageStrings.gr.TeamDetails)
      : (this.content = LanguageStrings.en.TeamDetails);

    document.querySelector("head title").innerText = this.content.MetaTitle;

    axios
      .get(constant.API_URL + `team/member/${this.$route.params.slug}`)

      .then((response) => {
        if (response.data.data) {
          this.team_data = response.data.data;

          /*
          this.team_data =  localStorage.getItem("siteLanguage") === "EN" ?
            response.data.data.filter((item)=> item.office.office_code==="PK" ) 
          : response.data.data.filter((item)=> item.office.office_code==="DE" ) 
          */
        }
      })
      .catch((err) => {
        this.err = err;
      });
  },
  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>
